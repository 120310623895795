$sh-student-performance-table-border: $els-thin-keyline-width solid $els-color-n2;
.c-sh-student-performance-table {
  .c-responsive-table-header {
    top: calc(4.8 * #{$els-space-1x1o4});
  }

  &__header {
    &--full-width {
      .c-els-table__sortable-button {
        flex: 1;
      }
    }

    &-group {
      @extend .c-sh-student-performance-table__header--full-width;
      position: relative;
      &:after {
        @extend .u-els-text-ellipsis;
        position: absolute;
        top: $els-space-1o2;
        bottom: calc(6 * #{$els-space});
        width: fit-content;
        margin-top: -$els-space-1o2;
        margin-left: calc(-0.063 * #{$els-space});
        padding: $els-space-1o2;
        color: $els-color-n9;
        background-color: $els-color-n1;
        border: $sh-student-performance-table-border;
        font-weight: bold;
        text-align: center;
        pointer-events: none;
      }
    }

    &-dce {
      @extend .c-sh-student-performance-table__header-group;
      &:after {
        content: 'Overall Performance';
        padding-left: calc(9 * #{$els-space});
        padding-right: calc(9 * #{$els-space});
      }
    }

    &-subjective-data-collection {
      @extend .c-sh-student-performance-table__header-group;
      &:after {
        content: 'Components of Student Performance Index';
        padding-left: calc(8.95 * #{$els-space});
        padding-right: calc(8.95 * #{$els-space});
      }
    }

    &-spi-compare-to-class-avg {
      @extend .c-sh-student-performance-table__header--full-width;
      .c-els-table__sortable-button {
        max-width: calc(2 * #{$els-space-5x - $els-space-1o2});
        margin: auto;
      }
    }
  }

  &__cell {
    &-comparison-bar-chart {
      &--warn {
        .c-comparison-bar-chart__display-text {
          color: $els-color-warn;
          right: calc(50% + (0.625 * #{$els-space})) !important;
        }
      }
      &--recommended {
        .c-comparison-bar-chart__display-text {
          color: $els-color-confirm;
          left: calc(50% + (0.625 * #{$els-space})) !important;
        }
      }
    }
    &-student-name {
      @extend .u-els-text-ellipsis;
    }
  }

  .c-responsive-table {
    &-body {
      &-cell {
        //Completed Assignments column
        &:nth-child(2) {
          padding-right: $els-space-3x;
        }
        //DCE column
        &:nth-child(3),
        //Subjective Data Collection column
        &:nth-child(6) {
          border-left: $sh-student-performance-table-border;
        }
        //Care Plan column
        &:nth-child(9) {
          border-right: $sh-student-performance-table-border;
        }
      }

      &-row {
        &:last-child {
          border-bottom: $sh-student-performance-table-border;
        }
      }
    }
  }

  .c-els-table {
    &__cell {
      &--header {
        padding-top: calc(#{$els-space-4x} + 0.6 * #{$els-space});
        //Completed Assignments column
        &:nth-child(1) {
          padding-right: $els-space-3x;
        }
        //DCE column
        &:nth-child(2) {
          border-left: $sh-student-performance-table-border;
        }
        //Care Plan column
        &:nth-child(8) {
          border-right: $sh-student-performance-table-border;
        }
        //Subjective Data Collection column
        &:nth-child(5) {
          border-left: $sh-student-performance-table-border;
          .c-els-table__sortable-button {
            margin-left: $els-space;
          }
        }
      }
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    .c-els-table {
      &__cell {
        &--header {
          padding-top: calc(#{$els-space-3x} + 0.3 * #{$els-space});
        }
      }
    }
  }

  @media screen and (max-width: map_get($custom-breakpoint, custom-desktop-screen)) {
    .c-sh-student-performance-table {
      &__header {
        &-dce {
          &:after {
            padding-left: calc(8 * #{$els-space} + #{$els-space-1o2});
            padding-right: calc(8 * #{$els-space} + #{$els-space-1o2});
          }
        }

        &-subjective-data-collection {
          &:after {
            padding-left: calc(8.45 * #{$els-space});
            padding-right: calc(8.45 * #{$els-space});
          }
        }
      }
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, desktop)) {
    .c-sh-student-performance-table {
      &__header {
        &-dce {
          &:after {
            bottom: auto;
            padding-left: calc(7 * #{$els-space} + 0.6 * #{$els-space});
            padding-right: calc(7 * #{$els-space} + 0.6 * #{$els-space});
          }
        }

        &-subjective-data-collection {
          &:after {
            bottom: auto;
            padding-left: calc(7.25 * #{$els-space});
            padding-right: calc(7.25 * #{$els-space});
          }
        }
      }
    }
  }
}
