$children-expandable-cell-padding-left: calc(#{$els-space-4x} + 0.125 * #{$els-space});
$children-cell-padding-left: calc(#{$els-space-3x} + 0.625 * #{$els-space});
$grant-children-cell-padding-left: calc(#{$space-6x} + 0.875 * #{$els-space});

.c-sh-objective-data-collection-category-table {
  &__cell {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-category-label {
      &-children {
        padding-left: $children-cell-padding-left;

        &--expandable {
          padding-left: $children-expandable-cell-padding-left;
        }
      }

      &-grant-children {
        padding-left: $grant-children-cell-padding-left;
      }
    }

    &-category-average-score {
      &-children {
        div {
          // Override els background color of average score column
          background-color: $els-color-n0  !important;
        }

        span {
          // Override els color of average score column 
          color: $els-color-n9  !important;
        }
      }
    }
  }

  &__header {
    padding: $els-space-1o8 $els-space;

    &-average-score {
      justify-content: center;
    }

    &-align-right {
      justify-content: end;
    }

    &-items-uncovered {
      justify-content: center;
      white-space: pre-line;
    }
  }

  &__row {
    &-children {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: $children-cell-padding-left;
        top: 0;
        height: $els-thin-keyline-width;
        width: calc(100% - #{$children-cell-padding-left});
        border-top: $els-thin-keyline-width solid $els-color-n2;
        z-index: 2;
      }

      &--expandable {
        &::before {
          left: $children-expandable-cell-padding-left;
          width: calc(100% - #{$children-expandable-cell-padding-left});
        }
      }
    }

    &-grant-children {
      @extend .c-sh-objective-data-collection-category-table__row-children;

      &::before {
        left: $grant-children-cell-padding-left;
        width: calc(100% - #{$grant-children-cell-padding-left});
      }
    }
  }

  &__average-score-column>div,
  &__cell-category-average-score-children>div {
    padding: $els-space-1x1o4;
    width: calc(8.5 * #{$els-space});
  }

  .c-responsive-table-body-row div {
    height: calc(3.875 * #{$els-space});
  }

  @media screen and (max-width: map-get($mq-breakpoints, tablet)) {
    &__expand-row {
      display: inline-block;
    }
  }
}
