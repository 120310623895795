$icon-size: $els-space-1x1o2;

.c-ps-report-card-recent-exams {
  min-height: $space-25x;
  grid-area: recent-exams-table;
  width: 100%;
  border-radius: $els-space-1o2;
  background-color: white;
  border: $els-thin-keyline-width solid $els-color-n3;
  padding: $els-space;

  &__pill {
    background-color: $els-color-extended-blue-0;
  }

  .c-table-filter {
    margin: $els-space 0 !important;
  }

  &__info-box {
    background-color: transparent !important;
    border: $els-thin-keyline-width solid $els-color-secondary;
    border-radius: $els-space-1o4;
    padding: $els-space-1o2 $els-space-3o4 !important;

    .c-info-box__content {
      margin-top: $els-space-1o2;
      font-size: $els-fs-meta;
      line-height: $els-fs-meta;
    }
  }

  .c-ps-information-solid-square-icon {
    height: $icon-size;
    width: $icon-size;
    min-width: $icon-size;
    min-height: $icon-size;
    fill: $els-color-secondary;
  }

  &__table {
    min-height: $space-25x;
    font-size: $els-fs-meta;

    .c-responsive-table-body {
      // examName
      .c-responsive-table-body-cell:first-child,
      // cohortName
      .c-responsive-table-body-cell:nth-child(3) {
        @extend .u-els-text-ellipsis;
      }

      // examScore
      .c-responsive-table-body-cell:nth-child(2) {
        padding: $els-space-1o4 $els-space-1o4 0 $els-space-1x;
      }
    }

    &-mean-hesi-score {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: $els-space-1o4;

      &-value {
        &-wrapper {
          display: flex;
          align-items: center;
          text-align: end;
        }
        min-width: $els-space-1x1o2;
        margin-top: $els-space-1o4;
      }
    }

    &-wrapper {
      .c-els-pagination {
        position: relative;
      }
    }
  }

  &__filter {
    &-header {
      display: flex;
      flex-direction: column;
    }
    &-describe {
      max-width: 80%;
      line-height: $els-space-1x1o4;
    }
    .c-table-filter-modal__row {
      border-bottom: $els-thin-solid-border;
  
      &:first-child {
        border-top: $els-thin-solid-border;
      }
    }
    .c-table-filter-modal__range-row-label {
      font-size: $els-fs-caption !important;
      align-content: center;
    }
    .c-table-filter__result {
      padding-top: 0 !important;
    }
  }

  &__export {
    width: calc(6.5 * #{$els-space});
    padding: calc(0.5 * #{$els-space});
    border-color: $els-color-secondary;
    min-height: $els-space-2x1o4;
    height: $els-space-2x1o4;
    display: flex;
    align-items: center;
    #download-icon {
      position: relative;
      top: $els-thin-keyline-width;
      color: $els-color-secondary;
    }
  }
}
