.c-sh-dashboard-details {
  &__wrapper {
    width: 100%;
    margin: auto;
    background-color: $els-color-n1;

    .c-els-tab-group {
      @extend .u-max-width-1400;
      margin: auto;
      padding-left: $els-space-5x;

      &__text {
        &:hover,
        &:active,
        &:focus {
          color: $els-color-text;
        }
      }

      &__item {
        &--active {
          .c-els-tab-group__text {
            color: $els-color-text;
          }
        }
      }
    }

    &__cjmm {
      .c-els-tab-group {
        padding-left: $els-space-1x;
      }
    }

    .o-els-container {
      border-top: $els-thin-keyline-width solid $els-color-n3;
      .c-els-tab-group__content {
        @extend .u-max-width-1400;
        margin: auto;
      }
      margin: 0;
      padding: $els-space $els-space-2x 0 $els-space-2x;
      background-color: $els-color-background;
    }
  }
}
