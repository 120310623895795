.c-sh-category-tab {
  &__below-dropdown {
    margin-top: calc(3.75 * #{$els-space});
  }

  &__chart {
    &-custom-bar-label {
      filter: drop-shadow(0 $els-thin-keyline-width $els-color-n1);
      stroke: $els-color-n2;
      fill: white;
      stroke-width: $els-thin-keyline-width;
    }
  }

  &__legend {
    &-wrapper {
      display: flex;
      justify-content: space-between;

      &-eae {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    height: calc(30 * #{$els-space});
  }

  &__info-box {
    margin-top: calc(-1 * #{$els-space});
  }

  .c-search-bar {
    flex-basis: calc(20 * #{$els-space})
  }
}
