$sh-assignment-engagement-thin-border: $els-thin-keyline-width solid $els-color-n2;
$sh-assignment-engagement-thick-border: $els-thick-keyline-width solid $els-color-n6;

.c-sh-assignment-engagement-table {
  .c-responsive-table-header {
    top: calc(4.875 * #{$els-space-1x1o4});
  }

  &__header {
    &--full-width {
      .c-els-table__sortable-button {
        flex: 1;
      }
    }

    &-group {
      @extend .c-sh-assignment-engagement-table__header--full-width;
      position: relative;

      &:after {
        @extend .u-els-text-ellipsis;
        position: absolute;
        top: $els-space-1x;
        width: fit-content;
        margin-top: -$els-space-1x;
        margin-left: calc(-0.063 * #{$els-space});
        padding: $els-space-1o2;
        color: $els-color-n9;
        background-color: $els-color-n1;
        border: $sh-assignment-engagement-thin-border;
        font-weight: bold;
        text-align: center;
        pointer-events: none;
      }
    }

    &-parent-interaction-time {
      @extend .c-sh-assignment-engagement-table__header-group;

      &:after {
        content: 'Engagement Metrics';
        padding: $els-space-1o2 calc(19.11 * #{$els-space});
      }

      @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
        &:after {
          padding: $els-space-1o2 calc(9.81 * #{$els-space});
        }
      }

      @media screen and (max-width: map_get($mq-breakpoints, desktop)) {
        &:after {
          padding: $els-space-1o2 calc(16.81 * #{$els-space});
        }
      }

      @media screen and (min-width: map_get($custom-breakpoint, custom-desktop-screen)) {
        &:after {
          padding: $els-space-1o2 calc(20.85 * #{$els-space});
        }
      }

      .c-responsive-table {
        &__sort-button-text {
          padding-left: $els-space-1x;
        }
      }
    }
  }

  &__cell {
    &-comparison-bar-chart {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--recommended {
        .c-comparison-bar-chart__display-text {
          left: calc(50% + #{0.5 * $els-space}) !important;
          color: $els-color-confirm;
        }
      }

      &--warn {
        .c-comparison-bar-chart__display-text {
          right: calc(50% + #{0.5 * $els-space}) !important;
          color: $els-color-warn;
        }
      }
    }
  }

  .c-responsive-table {
    &-body {
      &-cell {

        //Patient Interaction Time
        &:nth-child(4) {
          padding-left: 0;
          border-left: $sh-assignment-engagement-thin-border;
        }

        //Interview Guide column
        &:last-child {
          border-right: $sh-assignment-engagement-thin-border;
        }
      }
    }
  }

  .c-els-table {
    &__cell {
      &--header {
        padding-top: calc(#{$els-space-3x} + 0.5 * #{$els-space});
        border-bottom: $sh-assignment-engagement-thick-border;

        //Patient Interaction Time column
        &:nth-child(3) {
          padding-left: 0;
          border-left: $sh-assignment-engagement-thin-border;
        }

        //Interview Guide column
        &:nth-child(6) {
          border-right: $sh-assignment-engagement-thin-border;
        }
      }
    }
  }

}
