$table-shadow-color: rgba(226, 226, 226, 0.5);
$table-left-inner-shadow: inset $els-space-1o2 0 $els-space-1o4 -#{$els-space-1o4} $table-shadow-color;
$table-right-inner-shadow: inset -#{$els-space-1o2} 0 $els-space-1o4 -#{$els-space-1o4} $table-shadow-color;
$table-left-right-inner-shadow: $table-left-inner-shadow, $table-right-inner-shadow;
$table-right-shadow: $els-space-1o4 0 $els-space-1o4 $table-shadow-color;

.c-responsive-table {
  &--right-inner-shadow {
    box-shadow: $table-right-inner-shadow;
  }

  &-header {
    $page-header-height: calc(4.875 * #{$els-space});

    width: 100%;
    position: sticky;
    top: $page-header-height;
    background-color: white;
    z-index: 3;

    .c-els-table__cell--header {
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      color: $els-color-secondary;
    }

    &-row {
      width: 100%;
      display: grid;
      border-bottom: $els-thin-keyline-width solid $els-color-n2;
      .c-els-table__cell {
        border-bottom: none;
      }

      &__scrollable {
        display: grid;
        overflow: auto;
      }
    }

    &--left-inner-shadow {
      box-shadow: $table-left-inner-shadow;
    }

    &--right-inner-shadow {
      box-shadow: $table-right-inner-shadow;
    }

    &--left-right-inner-shadow {
      box-shadow: $table-left-right-inner-shadow;
    }
  }

  &-body {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &-row {
      display: grid;

      &-wrapper {
        border-bottom: $els-thin-keyline-width solid $els-color-n2;

        &--expand {
          background-color: $els-color-n0;
          .c-responsive-table-body-cell--sticky {
            background-color: $els-color-n0;
          }
        }
      }

      &:last-child {
        .c-els-table__cell {
          border: none;
        }
      }
    }

    &-cell {
      overflow: hidden;
      border-bottom: none;

      &--sticky {
        position: sticky;
        left: 0;
        background-color: white;
        z-index: 1;
      }

      &--right-shadow {
        box-shadow: $table-right-shadow;
      }

      &--expandable {
        display: flex;
        align-items: center;
        max-width: fit-content;
        color: $els-color-secondary;
        cursor: pointer;
      }
    }
  }
}
