@each $class, $color in $els-color-classes {
  .u-els-fill-#{$class} {
    fill: $color;
  }

  .u-els-stroke-#{$class} {
    stroke: $color;
  }

  .u-rechart-area-fill-#{$class} {
    .recharts-area-area {
      fill: $color;
    }
  }

  .u-rechart-area-stroke-#{$class} {
    .recharts-area-curve {
      stroke: $color;
    }
  }

  .u-rechart-reference-area-stroke-#{$class} {
    .recharts-reference-area-rect {
      stroke: $color;
    }
  }

  .u-rechart-reference-area-fill-#{$class} {
    .recharts-reference-area-rect {
      fill: $color;
    }
  }

  .u-rechart-reference-dot-fill-#{$class} {
    .recharts-reference-dot-dot {
      fill: $color;
    }
  }

  .u-recharts-line-#{$class} {
    .recharts-line-curve {
      stroke: $color;
    }
  }

  .u-recharts-line-dots-fill-#{$class} {
    .recharts-line-dot {
      fill: $color;
    }
  }

  .u-recharts-line-dots-stroke-#{$class} {
    .recharts-line-dot {
      stroke: $color;
    }
  }

  .u-recharts-reference-line-#{$class} {
    .recharts-reference-line-line {
      stroke: $color;
    }
  }

  .u-recharts-stop-color-#{$class} {
    stop-color: $color;
  }

  .u-border-#{$class} {
    border-color: $color !important;
  }
}

.u-els-stroke-custom-warn-1 {
  stroke: #8c271b;
}

.u-els-stroke-custom-acceptable-1 {
  stroke: #1d7413;
}

.u-els-stroke-custom-recommended-1 {
  stroke: #10420b;
}

.u-els-fill-custom-warn-1 {
  fill: #ebb1ab;
}

.u-els-fill-custom-warn-2 {
  fill: #d93725;
}

.u-els-fill-custom-warn-3 {
  fill: #972317;
}

.u-els-fill-custom-extended-green-1 {
  fill: #29a61b;
}

.u-els-fill-custom-extended-green-2 {
  fill: #b4e0af;
}

.u-els-fill-custom-acceptable-1 {
  fill: #bfe4bb;
}

.u-els-fill-custom-recommended-1 {
  fill: #1d7413;
}

.u-els-fill-custom-recommended-2 {
  fill: #a0bb7c;
}

.u-els-fill-color-info {
  fill: $els-color-info;
}

.u-els-fill-white {
  fill: #ffffff;
}

.u-els-stroke-extended-blue-11 {
  stroke: #0d5e7c;
}

.u-els-fill-extended-blue-11 {
  fill: #0d5e7c;
}

.u-els-fill-n2 {
  fill: $els-color-n2;
}

.u-els-fill-n6 {
  fill: $els-color-n6;
}

.u-els-fill-extended-teal-7 {
  fill: $els-color-extended-teal-7;
}

.u-els-fill-extended-blue-9 {
  fill: $els-color-extended-blue-9;
}

.u-els-fill-warn {
  fill: $els-color-warn;
}

.u-custom-fill-red-1 {
  fill: #b12c1e;
}

.u-custom-fill-orange-3 {
  fill: #e9711c;
}

.u-custom-fill-green-3 {
  fill: #4f7b16;
}

.u-custom-color-black-1 {
  color: #1a1a1a !important;
}

.u-custom-bg-color-pink-1 {
  background-color: #faebe9;
}

.u-custom-color-dark-green {
  color: #29a61b !important;
}

.u-custom-border-dark-green {
  border-color: #29a61b;
}

.u-els-fill-custom-green-1 {
  fill: #619913;
}

.u-els-fill-elsevier-orange {
  fill: #ff6c00;
}

.u-els-fill-custom-blue-1 {
  fill: #177da0 !important;
}

.u-els-fill-custom-red-2 {
  fill: #95241c !important;
}

.u-els-fill-custom-orange-2 {
  fill: #de8329 !important;
}

.u-els-fill-custom-green-2 {
  fill: #77cd47 !important;
}

.u-els-fill-custom-green-3 {
  fill: #147a0c !important;
}

.u-custom-color-green-1 {
  color: #29a61b;
}

.u-custom-color-orange-1 {
  color: #e9711d;
}

.u-custom-color-orange-3 {
  color: #e9711c;
}

.u-custom-color-dark {
  color: #000;
}

.u-custom-color-dark-2 {
  color: #2e2e2e;
}

.u-custom-color-dark-3 {
  color: #494949 !important;
}

.u-custom-bg-color-dark-blue-1 {
  background-color: #0b4768;
}

.u-custom-bg-color-gray-1 {
  background-color: #e2e2e2;
}

.u-custom-bg-color-gray-2 {
  background-color: #eeeeee !important;
}

.u-custom-bg-color-red-1 {
  background-color: #b12c1e !important;
}

.u-custom-bg-color-red-2 {
  background-color: #b12c1e;
}

.u-custom-bg-color-red-3 {
  background-color: #f3dfdd;
}

.u-custom-bg-color-red-4 {
  background-color: #95241c;
}

.u-custom-bg-color-orange-1 {
  background-color: #fdf1e8 !important;
}

.u-custom-bg-color-orange-2 {
  background-color: #de8329;
}

.u-custom-bg-color-orange-3 {
  background-color: #e9711c !important;
}

.u-custom-bg-color-green-1 {
  background-color: #ecf9dc !important;
}

.u-custom-bg-color-green-2 {
  background-color: #f5fbf4;
}

.u-custom-bg-color-green-3 {
  background-color: #4f7b16 !important;
}

.u-custom-bg-color-green-4 {
  background-color: #77cd47;
}

.u-custom-bg-color-green-5 {
  background-color: #147a0c;
}

.u-custom-bg-color-blue-1 {
  background-color: #eaf9ff !important;
}

.u-custom-bg-color-blue-2 {
  background-color: #3c6c86 !important;
}

.u-custom-bg-color-blue-3 {
  background-color: #f2fafd;
}

.u-custom-bg-color-blue-4 {
  background-color: #2b6589;
}

.u-custom-bg-color-blue-9 {
  background-color: #ccf3ff;
}

.u-custom-border-color-gray-1 {
  border-color: #d4d4d4 !important;
}
